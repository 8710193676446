import React, { useEffect } from 'react';
import { buildLogger, LoggableError } from 'logger';
import StackTrace from 'stacktrace-js';
import { node } from 'prop-types';
import { LIVE } from 'gatsby-env-variables';

import { useDataLoadContext } from 'contexts/data-load-context';
import { useStateContext } from 'contexts/state-context';

import { isAuthenticated } from 'services/authentication-service';
import { useAuth0 } from '@auth0/auth0-react';
import { getUserEmail } from 'apputil/user';

const emailRegex = RegExp(
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
);

const LogInWrapper = ({ children }) => {
  const logger = buildLogger();

  const stateContext = useStateContext();
  const authenticated = isAuthenticated();
  const { pageScriptsLoaded } = useDataLoadContext();
  const { loginWithRedirect } = useAuth0();

  useEffect(() => {
    const fetchAuthentication = async () => {
      try {
        const getLoggedInService = await import('services/logged-in-service');
        const { loggedIn } = getLoggedInService.default({
          stateContext,
        });
        const isLoggedIn = await loggedIn();
        if (!isLoggedIn) {
          const email = getUserEmail() || '';
          if (emailRegex.test(email)) {
            loginWithRedirect({
              prompt: 'select_account',
              login_hint: email,
              appState: {},
            });
          } else {
            loginWithRedirect({
              prompt: 'select_account',
              appState: {},
            });
          }
        }
      } catch (err) {
        const trace = await StackTrace.get();
        const { stack = '', message = '' } = err;
        logger.error(new LoggableError({
          data: { stack, caller: 'fetchAuthentication:onError' },
          message,
          trace,
        }));
      }
    };
    if (LIVE && authenticated) {
      fetchAuthentication();
    }
    pageScriptsLoaded();

  // eslint-disable-next-line
}, [authenticated]);

  return (<>{children}</>);
};

LogInWrapper.propTypes = { children: node.isRequired };

export default LogInWrapper;
